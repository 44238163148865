export default function FNDLogo() {
  return (
    <svg width="52" height="32" viewBox="0 0 52 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7427_29451)">
        <path
          d="M26.0002 2.55199L26.8459 0.30591L26.0002 -0.0124727L25.1546 0.30591L26.0002 2.55199ZM49.4887 11.3954L48.6431 13.6414L51.8887 11.3954H49.4887ZM2.51172 11.3954L1.66607 9.14927L0.111719 9.73448L0.111719 11.3954H2.51172ZM25.1546 4.79807L48.6431 13.6414L50.3344 9.14927L26.8459 0.30591L25.1546 4.79807ZM4.91172 29.0821V11.3954H0.111719V29.0821H4.91172ZM3.35736 13.6414L26.8459 4.79807L25.1546 0.30591L1.66607 9.14927L3.35736 13.6414ZM51.8887 11.3954V-12.125H47.0887V11.3954H51.8887ZM0.111719 29.0821V49.7947H4.91172V29.0821H0.111719Z"
          fill="white"
        />
        <path
          d="M19.9004 32V29.7638H21.554V21.3362H19.9004V19.1H26.0004V21.3362H24.3468V29.7638H26.0004V32H19.9004Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M38.0117 23.5355H38.0671V32H40.7121V19.1H37.7157L34.5899 25.1434H34.5529L31.39 19.1H28.4121V32H31.0571V23.4062H31.1126L32.0929 25.4391L33.3229 27.7123H35.8055L37.0683 25.4206L38.0117 23.5355Z"
          fill="white"
        />
        <path
          d="M43.1387 32V19.1H51.8887V21.595H45.9387V24.2378H51.0413V26.7143H45.9387V29.505H51.8887V32H43.1387Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7427_29451">
          <rect
            width="32"
            height="51.7771"
            fill="white"
            transform="translate(0.111328 32) rotate(-90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
