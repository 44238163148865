import { CategoryConfig, RetailerConfig } from '@/app/types/models';
import { BusinessCategory, RetailerEnum } from 'app/utils/constants';
import LowesLogo from '../components/images/LowesLogo';
import SamsLogo from '../components/images/SamsLogo';
import LowesTheme from '../components/layouts/themes/LowesTheme';
import SamsTheme from '../components/layouts/themes/SamsTheme';
import AceLogo from '../components/images/AceLogo';
import AceTheme from '../components/layouts/themes/AceTheme';
import FNDLogo from '../components/images/FNDLogo';
import FNDTheme from '../components/layouts/themes/FNDTheme';
import {
  LEGACY_MY_PROJECTS_URL_LOWES,
  LEGACY_MY_PROJECTS_URL_SAMS,
  LEGACY_MY_PROJECTS_URL_FLOORANDDECOR,
  LEGACY_MY_PROJECTS_URL_BJS,
} from '@/app/utils/constants';
import BjsLogo from '../components/images/BjsLogo';
import BjsTheme from '../components/layouts/themes/BjsTheme';
import React from 'react';
import { openPDF, replaceEnvInUrl } from '@/app/utils/helpers';
import {
  ButtonEventProperties,
  ButtonEvents,
  NavEventProperties,
} from '@/app/utils/trackingEvents';
import { useTranslations } from 'next-intl';
import useTracking from '@/app/hooks/useTracking';
import { SidebarItemProps } from '@/app/components/navigation/SidebarItem';
import { BsBriefcase, BsCart, BsFileSpreadsheet } from 'react-icons/bs';

// Typed as string so as to not *require* all categories to have a config
const categories: Record<string, CategoryConfig> = {
  [BusinessCategory.Bathrooms]: {
    categoryId: 0,
    categoryName: BusinessCategory.Bathrooms,
    quotePagePath: '',
    backgroundImage: 'baths.jpg',
  },
  [BusinessCategory.BathAccessibility]: {
    categoryId: 0,
    categoryName: BusinessCategory.BathAccessibility,
    quotePagePath: '',
    backgroundImage: 'bath_accessibility.png',
  },
  [BusinessCategory.HomeOrganization]: {
    categoryId: 0,
    categoryName: BusinessCategory.HomeOrganization,
    quotePagePath: '',
    backgroundImage: 'home_organization.jpeg',
  },
  [BusinessCategory.Sheds]: {
    categoryId: 0,
    categoryName: BusinessCategory.Sheds,
    quotePagePath: '/sheds-quote',
    backgroundImage: 'sheds.jpg',
  },
  [BusinessCategory.Flooring]: {
    categoryId: 0,
    categoryName: BusinessCategory.Flooring,
    quotePagePath: '/flooring-quote',
    backgroundImage: 'flooring.jpeg',
  },
  [BusinessCategory.Default]: {
    categoryId: 0,
    categoryName: BusinessCategory.Default,
    quotePagePath: '',
    backgroundImage: '',
  },
  [BusinessCategory.Countertops]: {
    categoryId: 0,
    categoryName: BusinessCategory.Countertops,
    quotePagePath: '/countertops-quote',
    backgroundImage: 'countertops.jpeg',
  },
  [BusinessCategory.Insulation]: {
    categoryId: 0,
    categoryName: BusinessCategory.Insulation,
    quotePagePath: '',
    backgroundImage: 'insulation.jpeg',
  },
  [BusinessCategory.ArtificialTurf]: {
    categoryId: 0,
    categoryName: BusinessCategory.ArtificialTurf,
    quotePagePath: '',
    backgroundImage: 'artificial_turf.jpeg',
  },
  [BusinessCategory.WaterTreatment]: {
    categoryId: 0,
    categoryName: BusinessCategory.WaterTreatment,
    quotePagePath: '',
    backgroundImage: '',
  },
  [BusinessCategory.Roofing]: {
    categoryId: 0,
    categoryName: BusinessCategory.Roofing,
    quotePagePath: '',
    backgroundImage: 'roofing.jpeg',
  },
  [BusinessCategory.Siding]: {
    categoryId: 0,
    categoryName: BusinessCategory.Siding,
    quotePagePath: '',
    backgroundImage: 'siding.jpeg',
  },
  [BusinessCategory.Assembly]: {
    categoryId: 0,
    categoryName: BusinessCategory.Assembly,
    quotePagePath: '',
    backgroundImage: '',
  },
  [BusinessCategory.Paint]: {
    categoryId: 0,
    categoryName: BusinessCategory.Paint,
    quotePagePath: '',
    backgroundImage: '',
  },
  [BusinessCategory.GarageDoorOpener]: {
    categoryId: 0,
    categoryName: BusinessCategory.GarageDoorOpener,
    quotePagePath: '',
    backgroundImage: 'garage_doors.jpeg',
  },
  [BusinessCategory.XLSlabs]: {
    categoryId: 0,
    categoryName: BusinessCategory.XLSlabs,
    quotePagePath: '',
    backgroundImage: 'xl_slabs.jpeg',
  },
  [BusinessCategory.Shutters]: {
    categoryId: 0,
    categoryName: BusinessCategory.Shutters,
    quotePagePath: '',
    backgroundImage: 'shutters.jpeg',
  },
  [BusinessCategory.Generators]: {
    categoryId: 0,
    categoryName: BusinessCategory.Generators,
    quotePagePath: '',
    backgroundImage: 'generators.jpg',
  },
  [BusinessCategory.Cabinets]: {
    categoryId: 0,
    categoryName: BusinessCategory.Cabinets,
    quotePagePath: '',
    backgroundImage: 'cabinets.jpeg',
  },
  [BusinessCategory.EVCharging]: {
    categoryId: 0,
    categoryName: BusinessCategory.EVCharging,
    quotePagePath: '',
    backgroundImage: '',
  },
  [BusinessCategory.Boiler]: {
    categoryId: 0,
    categoryName: BusinessCategory.Boiler,
    quotePagePath: '',
    backgroundImage: 'boiler.jpeg',
  },
  [BusinessCategory.ShowerDoor]: {
    categoryId: 0,
    categoryName: BusinessCategory.ShowerDoor,
    quotePagePath: '',
    backgroundImage: 'shower_door.jpeg',
  },
  [BusinessCategory.LivableHome]: {
    categoryId: 0,
    categoryName: BusinessCategory.LivableHome,
    quotePagePath: '',
    backgroundImage: '',
  },
  [BusinessCategory.WaterHeaters]: {
    categoryId: 0,
    categoryName: BusinessCategory.WaterHeaters,
    quotePagePath: '/water-heater-quote',
    backgroundImage: '',
  },
};

export const enum FnDSidebarLinks {
  // How it works
  EASY_AS_1_2_3 = 'https://pros.myhomeprojectcenter.com/how-it-works/easy-as-1-2-3/',
  WHY_WE_RE_DIFFERENT = 'https://pros.myhomeprojectcenter.com/how-it-works/why-we-re-different/',
  CERTIFIED_CONTRACTORS = 'https://pros.myhomeprojectcenter.com/how-it-works/certified-contractors/',
  CUSTOMER_PLEDGE = 'https://pros.myhomeprojectcenter.com/how-it-works/customer-pledge/',
  PREPARING_FOR_YOUR_PROJECT = 'https://pros.myhomeprojectcenter.com/how-it-works/preparing-for-your-project/',

  // Products
  BATH_REMODEL = `https://pros.myhomeprojectcenter.com/products/bath-remodel/`,
  BATHROOM_VANITIES = 'https://pros.myhomeprojectcenter.com/products/bathroom-vanities/',
  COUNTERTOPS = 'https://pros.myhomeprojectcenter.com/products/countertops/',
  FAUCETS_SHOWER_HEADS = 'https://pros.myhomeprojectcenter.com/products/faucets-shower-heads/',
  FLOORING = 'https://pros.myhomeprojectcenter.com/products/flooring/',
  SHOWER_DOORS = 'https://pros.myhomeprojectcenter.com/products/shower-doors/',
  SINKS = 'https://pros.myhomeprojectcenter.com/products/sinks/',
  XL_SLABS = 'https://pros.myhomeprojectcenter.com/products/xl-slabs/',
  SHOP_FLOOR_DECOR = 'https://www.flooranddecor.com/',

  // Measure & Estimate
  MEASURE_ESTIMATE = 'https://pros.myhomeprojectcenter.com/lead-form/',
}

const FnDSidebarItems = (): SidebarItemProps[] => {
  const t = useTranslations('components.navMenu.sidebar');
  const { events, trackClick } = useTracking();

  return [
    {
      id: '1',
      name: t('buttons.How It Works'),
      isCollapsible: true,
      icon: <BsBriefcase size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Easy as 1-2-3`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.EASY_AS_1_2_3));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.EASY_AS_1_2_3),
              category: t(`buttons.Easy as 1-2-3`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Why We're Different`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.WHY_WE_RE_DIFFERENT));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.WHY_WE_RE_DIFFERENT),
              category: t(`buttons.Why We're Different`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Certified Contractors`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.CERTIFIED_CONTRACTORS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.CERTIFIED_CONTRACTORS),
              category: t(`buttons.Certified Contractors`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Customer Pledge`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.CUSTOMER_PLEDGE));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.CUSTOMER_PLEDGE),
              category: t(`buttons.Customer Pledge`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Preparing for Your Project`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.PREPARING_FOR_YOUR_PROJECT));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.PREPARING_FOR_YOUR_PROJECT),
              category: t(`buttons.Preparing for Your Project`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '2',
      name: t('buttons.Products'),
      isCollapsible: true,
      icon: <BsCart size={18} style={{ marginLeft: '3px' }} />,
      subItems: [
        {
          name: t(`buttons.Bath Remodel`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.BATH_REMODEL));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.BATH_REMODEL),
              category: t(`buttons.Bath Remodel`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Bathroom Vanities`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.BATHROOM_VANITIES));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.BATHROOM_VANITIES),
              category: t(`buttons.Bathroom Vanities`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Countertops`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.COUNTERTOPS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.COUNTERTOPS),
              category: t(`buttons.Countertops`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Faucets/Shower Heads`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.FAUCETS_SHOWER_HEADS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.FAUCETS_SHOWER_HEADS),
              category: t(`buttons.Faucets/Shower Heads`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Flooring`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.FLOORING));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.FLOORING),
              category: t(`buttons.Flooring`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Shower Doors`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.SHOWER_DOORS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.SHOWER_DOORS),
              category: t(`buttons.Shower Doors`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Sinks`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.SINKS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.SINKS),
              category: t(`buttons.Sinks`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.XL Slabs`),
          onClick: () => {
            openPDF(replaceEnvInUrl(FnDSidebarLinks.XL_SLABS));
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: replaceEnvInUrl(FnDSidebarLinks.XL_SLABS),
              category: t(`buttons.XL Slabs`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
        {
          name: t(`buttons.Shop Floor & Decor`),
          onClick: () => {
            openPDF(FnDSidebarLinks.SHOP_FLOOR_DECOR);
            const trackingProps: NavEventProperties = {
              component: 'Sidebar',
              target: FnDSidebarLinks.SHOP_FLOOR_DECOR,
              category: t(`buttons.Shop Floor & Decor`),
            };
            trackClick(events.NAV_CLICKED, trackingProps);
          },
        },
      ],
      open: false,
      onClick: () => {
        const trackingProps: ButtonEventProperties = {
          component: 'Sidebar',
          buttonEvent: ButtonEvents.LeftNavToggled,
        };
        trackClick(events.BUTTON_CLICKED, trackingProps);
      },
    },
    {
      id: '3',
      name: t('buttons.Measure & Estimate'),
      isCollapsible: false,
      icon: <BsFileSpreadsheet size={18} style={{ marginLeft: '3px' }} />,
      onClick: () => {
        openPDF(replaceEnvInUrl(FnDSidebarLinks.MEASURE_ESTIMATE));
        const trackingProps: NavEventProperties = {
          component: 'Sidebar',
          target: replaceEnvInUrl(FnDSidebarLinks.MEASURE_ESTIMATE),
        };
        trackClick(events.NAV_CLICKED, trackingProps);
      },
    },
  ];
};

const lowesConfig: RetailerConfig = {
  categories: [
    categories[BusinessCategory.Bathrooms],
    categories[BusinessCategory.BathAccessibility],
    categories[BusinessCategory.Sheds],
    categories[BusinessCategory.Default],
    categories[BusinessCategory.Countertops],
    categories[BusinessCategory.Flooring],
    categories[BusinessCategory.HomeOrganization],
    categories[BusinessCategory.ArtificialTurf],
    categories[BusinessCategory.Insulation],
    categories[BusinessCategory.WaterTreatment],
    categories[BusinessCategory.Roofing],
    categories[BusinessCategory.Siding],
    categories[BusinessCategory.Assembly],
    categories[BusinessCategory.Paint],
    categories[BusinessCategory.GarageDoorOpener],
    categories[BusinessCategory.XLSlabs],
    categories[BusinessCategory.Shutters],
    categories[BusinessCategory.Generators],
    categories[BusinessCategory.Cabinets],
    categories[BusinessCategory.EVCharging],
    categories[BusinessCategory.Boiler],
    categories[BusinessCategory.ShowerDoor],
    categories[BusinessCategory.LivableHome],
    categories[BusinessCategory.WaterHeaters],
  ],
  retailerId: RetailerEnum.Lowes,
  placeholderImageUrl: 'lowes_placeholder.png',
  faviconUrl: 'lowes_favicon.ico',
  name: "Lowe's",
  logo: <LowesLogo />,
  theme: LowesTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_LOWES,
  accessibilityStatement: 'https://lowes.myhomeprojectcenter.com/accessibility-statement/',
  phoneNumber: '8774771115',
};

const samsConfig: RetailerConfig = {
  categories: [categories[BusinessCategory.Sheds]],
  retailerId: RetailerEnum.Sams,
  placeholderImageUrl: 'sams_placeholder.png',
  faviconUrl: 'sams_favicon.ico',
  name: "Sam's",
  logo: <SamsLogo />,
  theme: SamsTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_SAMS,
  accessibilityStatement: 'https://confidentremodels.com/samsclub/about/accessibility-statement',
  phoneNumber: '8774771121',
};

const fndConfig: RetailerConfig = {
  categories: [categories[BusinessCategory.Sheds]],
  retailerId: RetailerEnum.FND,
  placeholderImageUrl: 'fnd_placeholder.png',
  faviconUrl: 'fnd_favicon.ico',
  name: 'Floor & Decor',
  logo: <FNDLogo />,
  theme: FNDTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_FLOORANDDECOR,
  accessibilityStatement: 'https://pros.myhomeprojectcenter.com/accessibility-statement/',
  phoneNumber: '8774771017',
  customerPledge: 'https://pros.myhomeprojectcenter.com/how-it-works/customer-pledge/',
  sidebarItems: FnDSidebarItems,
};

const bjsConfig: RetailerConfig = {
  categories: [categories[BusinessCategory.Sheds]],
  retailerId: RetailerEnum.BJs,
  placeholderImageUrl: 'bjs_placeholder.png',
  faviconUrl: 'bjs_favicon.ico',
  name: 'bjs',
  logo: <BjsLogo />,
  theme: BjsTheme,
  legacyMyProjectsUrl: LEGACY_MY_PROJECTS_URL_BJS,
  phoneNumber: '8772574747',
};

const aceConfig: RetailerConfig = {
  categories: [categories[BusinessCategory.Sheds]],
  retailerId: RetailerEnum.AceHardware,
  placeholderImageUrl: 'ace_placeholder.png',
  faviconUrl: 'ace_favicon.ico',
  name: 'ace',
  logo: <AceLogo />,
  theme: AceTheme,
  phoneNumber: '8669104833',
};

export default [lowesConfig, samsConfig, fndConfig, aceConfig, bjsConfig];
